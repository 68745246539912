.main_wrapper {
    display: grid;

    grid-template-columns: 30% auto;
    grid-template-rows: 40% 30% 30%;

    grid-template-areas: 
    "moneyflow chart"
    "income chart"
    "expense chart"
   ;

    /* overflow: auto; */
}

/* Test for mobile screen */
@media only screen and (max-width: 960px){
    .main_wrapper {
    
        grid-template-columns: auto;
        grid-template-rows: 15vh 15vh 15vh minmax(600px, auto);
    
        grid-template-areas: 
        "moneyflow"
        "income"
        "expense"
        "chart"
       ;
    
    }
}

.main_titles {
    font-weight: bold;
}

.main_values {
    font-style: oblique;
}

.main_summary_data {
    margin: auto;
    grid-area: moneyflow;
}

.main_income_data {
    grid-area: income;
}

.main_expense_data {
    grid-area: expense;
}

.main_summary_data, .main_income_data, .main_expense_data{
    margin: auto;

    width: 100%;
    height: 100%;
    text-align: center;

    display: flex;
    flex-direction: row;

    box-shadow: -10px 0 10px 10px #e8eaf6;

    align-items: center;
    justify-content: space-around;
}

#main_chart {
    grid-area: chart;

    height: 90%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    /* background: lightblue; */
}