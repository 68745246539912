.income_wrapper, .expense_wrapper{
    position: relative;

    display: grid;
    grid-template-columns: 33% auto;
    grid-template-rows: 20vh minmax(70vh, auto) auto;
    grid-template-areas: 
    "total buttons"
    "chart chart"
    "cards cards";
}

/* Test for mobile screen */
@media only screen and (max-width: 960px){
    .income_wrapper, .expense_wrapper{
        grid-template-columns: auto;
        grid-template-rows: auto auto minmax(600px, auto) auto;
        grid-template-areas: 
        "total" 
        "buttons"
        "chart"
        "cards";
    }
}

.buttons {
    grid-area: buttons;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;

    /* background: blue; */
    padding: 5px;

    box-shadow: 5px 5px 5px 5px #e8eaf6;
}

.summary_data{
    grid-area: total;

    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;

    /* background: aqua; */
    box-shadow: 5px 5px 5px 5px #e8eaf6;
}

.chart_area {
    grid-area: chart;

    height: 95%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    /* background: green; */
    
}

.detail_cards {
    grid-area: cards;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-wrap: wrap;
    box-shadow: 5px 0px 5px 5px #e8eaf6;
    
}

/* Buttons in summary cards */
.card_buttons {
    background: none;
    border: none;
    display: block;

    font-weight: bold;
    font-size: large;
}

.card_buttons:hover {
    cursor: pointer;
    color: yellowgreen;
}

/* Income page */
.income_list {
    width: 100%;
    position: relative;
    padding: 10px;

    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: left;

    flex-wrap: wrap;
}
.income_list li {
    position: relative;
    background: #e1f5fe;

    min-width: calc(100% * 1/4);
    max-width: calc(100% * 1/3);
    width: auto;
    height: auto;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    flex-wrap: wrap;

    padding: 10px;
    margin: 15px;

    box-shadow: 
        -1px 1px 1px 1px #ffffff,
        -2px 2px 1px 1px #dbdbdb,
        -3px 3px 1px 1px #c2c2c2,
        -5px 5px 1px 1px #a5a5a5;
}

.module_buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    justify-self: right;
}

#income_module_source {
    font-size: xx-large;
    font-family: monospace;

    margin-left: -10px;
    margin-top: -20px;
    padding: 10px 0px 10px 20px;
    
    width: 100%;

    background: #263b2c;
    color: white;

    text-align: left;
    word-wrap: break-word;

    font-size: 22px;
}

#income_module_amount {
    font-weight: bold;
    font-style: oblique;
    font-size: 20px;

    width: auto;
    margin-left: -10px;
    padding: 10px 0px 10px 10px;

    word-wrap: break-word;
    
    width: auto;
    text-align: center;
}


/* Expense page */
.expense_category_card {
    min-width: calc(100% * 1/2);
    max-width: calc(100% * 0.75);
    width: auto;
    height: auto;

    background: #e1f5fe;

    padding: 10px;

    margin: 10px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: left;
    flex-wrap: wrap;

    box-shadow: 
        -1px 1px 1px 1px #ffffff,
        -2px 2px 1px 1px #dbdbdb,
        -3px 3px 1px 1px #c2c2c2,
        -5px 5px 1px 1px #a5a5a5;
}

.expense_list_category {
    width: 100%;
    color: white;
    background: #263b2c;
    text-align: center;
    font-weight: bolder;
}

.expense_list {
    width: 100%;
    list-style-type: none;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    flex-wrap: wrap;
}

.expense_li_item {
    font-weight: bold;
}

.expense_li_amount {
    font-style: oblique;
    align-self: center;
}


.expense_list li {
    width: 100%;
    font-size: medium;

    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    padding: 10px;

    margin-left: -20px;
}

/* Button sections in render_area */
.buttons {
    display: flex;
    flex-direction: row;
}

.all_buttons {
    border: none;

    margin: 3px;
    width: 150px;
    height: 50px;

    font-weight: bold;

    box-shadow: 3px 3px 0 0 silver,
                -3px -3px 0 0 silver;

    transition: all 200ms ease-in-out;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    flex-wrap: wrap;
}

.all_buttons:hover {
    cursor: pointer;
    background: lightseagreen;
}