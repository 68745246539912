/* Edit Modal  */
/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content/Box */
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: auto; /* Could be more or less, depending on screen size */
    float: right;
    margin-right: 10%;

    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-evenly;

    border-radius: 20px;
  }

.modal-content input {
  width: 220px;
  line-height: 25px;
}

.modal_buttons {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
}